import { configureStore, PreloadedState, StateFromReducersMapObject } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import appReducer, { initialState as initialAppState, AppState as AppSliceState } from './app';
import accountPanelReducer, {
    AccountPanelState,
    initialState as initialAccountPanelState,
} from 'packages/common/state/accountPanel';
import { reducer as roomReducer, initialState as initialRoomState, RoomState } from './room';
import contentReducer, {
    initialState as initialContentState,
    ContentState,
} from 'packages/common/state/content';
import chatReducer, {
    initialState as initialChatState,
    ChatState,
} from 'packages/common/state/chat';
import userReducer, {
    initialState as initialUserState,
    UserState,
} from 'packages/common/state/user';
import {
    reducer as messagesReducer,
    MessagesState,
    initialState as initialMessagesState,
    MessageListState,
} from 'packages/common/state/messages';
import { isProduction } from 'utils/env';
import { LocalStorageKey, localStorageGet } from 'constants/localStorage';
import tracking from './middleware/tracking';
import roomParticipant from './middleware/roomParticipant';
import roomNotifications from './middleware/roomNotifications';
import { ChannelType } from 'packages/common/text-chat/types';
import localStorage from './middleware/localStorage';
import { PlaybackCookie } from '../utils/cookies';
import Cookie from 'js-cookie';
import { DeviceInfo, ModalType } from '../typescript/typings';
import { SocialProvider } from 'packages/common/auth/components/types';

const getPreloadedState = (): PreloadedState<AppState> => {
    if (typeof window === 'undefined') {
        return {
            accountPanel: initialAccountPanelState,
            app: initialAppState,
            room: initialRoomState,
            user: initialUserState,
            chat: initialChatState,
            content: initialContentState,
            messages: initialMessagesState,
        };
    }

    const url = new URL(window.location.href);
    const oauthProvider = <SocialProvider>url.searchParams.get('provider');
    const magicCredential = url.searchParams.get('magic_credential');
    const roomCount = localStorageGet(LocalStorageKey.RoomCount, 0);
    const stageVolume = localStorageGet(LocalStorageKey.StageVolume, 100);
    const streamVolume = localStorageGet(LocalStorageKey.StreamVolume, 50);
    const pinnedHideTime = localStorageGet(LocalStorageKey.PinnedMessages, 0);
    const notificationsDisabled = localStorageGet(LocalStorageKey.ChatNotificationsDisabled, false);
    const messagesOpen = localStorageGet(LocalStorageKey.MessagesOpen, true);
    const accessToken = Cookie.get(PlaybackCookie.AccessToken);
    const deviceInfoCookie = Cookie.get(PlaybackCookie.DeviceInfo);
    const deviceInfo: DeviceInfo = deviceInfoCookie ? JSON.parse(deviceInfoCookie) : null;

    return {
        app: {
            ...initialAppState,
            roomCount,
            geo: deviceInfo?.geo || {},
            mobile: !!deviceInfo?.isMobile,
            osName: deviceInfo?.os,
            osVersion: deviceInfo?.osVersion,
            browserName: deviceInfo?.browser,
            browserVersion: deviceInfo?.browserVersion,
            deviceType: deviceInfo?.deviceType ?? '',
            deviceVendor: deviceInfo?.deviceVendor ?? '',
            modal: oauthProvider || magicCredential ? { type: ModalType.Login } : null,
        },
        user: {
            ...initialUserState,
            authRedirectCredential: magicCredential ?? '',
            authRedirectProvider: oauthProvider,
            accessToken: accessToken ?? '',
        },
        chat: {
            ...initialChatState,
            volume: stageVolume,
        },
        content: {
            ...initialContentState,
            volume: streamVolume,
        },
        messages: {
            ...initialMessagesState,
            open: deviceInfo?.isMobile ? false : messagesOpen,
            notificationsEnabled: !notificationsDisabled,
            [ChannelType.Room]: {
                ...initialMessagesState[ChannelType.Room],
                pinnedHideTime,
            },
        },
        room: {
            ...initialRoomState,
        },
        accountPanel: {
            ...initialAccountPanelState,
        },
    };
};

const reducer = {
    app: appReducer,
    room: roomReducer,
    content: contentReducer,
    chat: chatReducer,
    user: userReducer,
    messages: messagesReducer,
    accountPanel: accountPanelReducer,
};

export type AppState = StateFromReducersMapObject<typeof reducer>;

const makeStore = () =>
    configureStore({
        reducer,
        middleware: [roomNotifications, roomParticipant, localStorage, tracking],
        devTools: !isProduction(),
        preloadedState: getPreloadedState(),
    });

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
