import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { PopupNotification, NotificationType } from 'packages/common/base/types';
import { Modal, ModalType } from 'typescript/typings';
import { exitRoom, setRoom, updateRoomName } from 'packages/common/state/room/sharedActions';
import { denyMediaPermission } from 'packages/common/state/chat/sharedActions';
import { logout } from 'packages/common/state/user/sharedActions';
import { init } from './sharedActions';
import { Room } from 'packages/common/api-client/types';
import { RoomEntryType } from 'packages/common/base/utils/tracking';
import { HYDRATE } from 'next-redux-wrapper';
import { NextRequest } from 'next/server';

interface AppSessionState {
    rooms: Room[];
    archivedRooms: Room[];
    loadingRooms: boolean;
    hasLoadedRooms: boolean;
    roomCount: number;
    avatarObjectUrl: string;
    avatarImageUrl: string;
    notification: PopupNotification | null;
    modal: Modal | null;
    hasSubmittedFeedback: boolean;
    roomEntryType: RoomEntryType | null;
    supportEnabled: boolean;
    trackingQueue: AnyAction[];
    geo: NextRequest['geo'];
}

export interface AppState extends AppSessionState {
    mobile: boolean;
    browserName: string;
    browserVersion: string | number;
    osName: string;
    osVersion: string | number;
    deviceType: string;
    deviceModel: string;
    deviceVendor: string;
    navigating: boolean;
    loaderVisible: boolean;
    hasUserInteracted: boolean;
    idle: boolean;
}

const initialAppSessionState: AppSessionState = {
    rooms: [],
    archivedRooms: [],
    loadingRooms: false,
    hasLoadedRooms: false,
    roomCount: 0,
    avatarObjectUrl: '',
    avatarImageUrl: '',
    notification: null,
    modal: null,
    hasSubmittedFeedback: false,
    roomEntryType: null,
    supportEnabled: false,
    trackingQueue: [],
    geo: {},
};

export const initialState: AppState = {
    ...initialAppSessionState,
    mobile: false,
    osName: '',
    osVersion: '',
    browserName: '',
    browserVersion: '',
    deviceType: '',
    deviceModel: '',
    deviceVendor: '',
    navigating: false,
    loaderVisible: false,
    hasUserInteracted: false,
    idle: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMobile(state) {
            state.mobile = true;
        },
        setGeo(state, action: PayloadAction<NextRequest['geo']>) {
            state.geo = action.payload;
        },
        submitFeedback(state, _: PayloadAction<{ feedback: any; inRoom: boolean }>) {
            state.hasSubmittedFeedback = true;
        },
        showNotification(state, action: PayloadAction<PopupNotification>) {
            state.notification = action.payload;
        },
        dismissNotification(state) {
            state.notification = null;
        },
        showModal(state, action: PayloadAction<Modal>) {
            state.modal = action.payload;
        },
        dismissModal(state, action: PayloadAction<ModalType | undefined>) {
            if (typeof action.payload === 'undefined' || state.modal?.type === action.payload) {
                state.modal = null;
            }
        },
        addNewRoom(state, action: PayloadAction<Room>) {
            state.rooms.unshift(action.payload);
            state.roomCount += 1;
        },
        setRooms(state, action: PayloadAction<Room[]>) {
            console.log('set rooms');
            const isStreaming = (room: Room) => {
                return room.lastContentStream.id && !room.lastContentStream.stoppedOn;
            };

            state.rooms = [...action.payload].sort((room1, room2) => {
                // Show actively streaming rooms first
                const isRoom1Streaming = isStreaming(room1);
                const isRoom2Streaming = isStreaming(room2);

                if (isRoom1Streaming && isRoom2Streaming) {
                    return room2.activeMembers.length - room1.activeMembers.length;
                }

                if (isRoom1Streaming) return -1;
                if (isRoom2Streaming) return 2;

                // Then order by most recently streamed
                if (room1.lastContentStream.stoppedOn && room2.lastContentStream.stoppedOn) {
                    return (
                        new Date(room2.lastContentStream.stoppedOn).getTime() -
                        new Date(room1.lastContentStream.stoppedOn).getTime()
                    );
                }

                if (room1.lastContentStream.stoppedOn) {
                    return -1;
                }

                if (room2.lastContentStream.stoppedOn) {
                    return 1;
                }

                return 0;
            });

            state.roomCount = action.payload.length;
            state.loadingRooms = false;
            state.hasLoadedRooms = true;
        },
        setArchivedRooms(state, action: PayloadAction<Room[]>) {
            state.archivedRooms = action.payload;
        },
        updateRoom(state, action: PayloadAction<Room>) {
            const i = state.rooms.findIndex((room) => room.id === action.payload.id);
            if (i !== -1) {
                state.rooms[i] = action.payload;
            }
        },
        loadRoomsStart(state) {
            state.loadingRooms = true;
        },
        setAvatarObjectUrl(state, action: PayloadAction<string>) {
            state.avatarObjectUrl = action.payload;
        },
        setAvatarImageUrl(state, action: PayloadAction<string>) {
            state.avatarImageUrl = action.payload;
        },
        setRoomEntryType(state, action: PayloadAction<RoomEntryType>) {
            state.roomEntryType = action.payload;
        },
        setHasUserInteracted(state) {
            state.hasUserInteracted = true;
        },
        setNavigating(state, action: PayloadAction<boolean>) {
            state.navigating = action.payload;
        },
        setLoaderVisible(state, action: PayloadAction<boolean>) {
            state.loaderVisible = action.payload;
        },
        openSupportChat(state) {
            state.supportEnabled = true;
        },
        closeSupportChat(state) {
            state.supportEnabled = false;
        },
        trackingQueuePush(state, action: PayloadAction<AnyAction>) {
            state.trackingQueue.push(action.payload);
        },
        trackingQueuePop(state) {
            state.trackingQueue.shift();
        },
        setIdle(state, action: PayloadAction<boolean>) {
            state.idle = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action: any) => {
                return {
                    ...state,
                    mobile: !!action.payload.app.mobile,
                };
            })
            .addCase(init, (state, action) => {
                return {
                    ...state,
                    ...action.payload,
                };
            })
            .addCase(setRoom, (state, action) => {
                const i = state.rooms.findIndex((room) => room.id === action.payload.id);
                if (i !== -1) {
                    state.rooms[i] = action.payload;
                }
            })
            .addCase(exitRoom, (state) => {
                state.roomEntryType = null;
            })
            .addCase(updateRoomName, (state, action) => {
                const room = state.rooms.find((room) => room.id === action.payload.roomID);
                if (room) {
                    room.name = action.payload.name;
                }
            })
            .addCase(denyMediaPermission, (state, action) => {
                const { type } = action.payload;
                state.notification = {
                    type:
                        type === 'mic'
                            ? NotificationType.MicPermissionMissing
                            : NotificationType.CameraPermissionMissing,
                };
            })
            .addCase(logout, (state) => {
                return {
                    ...state,
                    ...initialAppSessionState,
                };
            });
    },
});

export const {
    setMobile,
    setGeo,
    submitFeedback,
    showNotification,
    dismissNotification,
    showModal,
    dismissModal,
    addNewRoom,
    setRooms,
    setArchivedRooms,
    updateRoom,
    loadRoomsStart,
    setAvatarObjectUrl,
    setAvatarImageUrl,
    setRoomEntryType,
    setHasUserInteracted,
    setNavigating,
    setLoaderVisible,
    openSupportChat,
    closeSupportChat,
    trackingQueuePush,
    trackingQueuePop,
    setIdle,
} = appSlice.actions;

export * from './sharedActions';

export default appSlice.reducer;
